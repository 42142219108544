import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { versionCredit4API } from './versions-credits-helpers';
import FormError from '@/views/common/FormError.vue';
import Modal from "@/views/modal/Modal.vue";

const defaultErrorMessages = {
    versionId: null,
    credit: null,
    days: null,
};

export default {
    name: "CreditModal",
    props: ["vcredit"],
    components: { Multiselect, FormError, Modal },
    data() {
        return {
            vcr: this.vcredit || {
                id: null,
                project: null,
                version: null,
                customer: null,
                opportunity: null,
                credit: null,
                creditDays: null,
                vcrDays: null,
                days: null,
                description: '',
            },
            originalVcrDays: this?.vcredit?.days,
            versions: [],
            opportunities: [],
            credits: [],
            errors: {
                version: false,
                credit: false,
                days: false,
            },
            errorMessages: defaultErrorMessages
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            projects: "Project/list/projects",
            customers: "Credit/list/customers"
        }),
        creditDays() {
            if (!this.vcr.credit) return null;
            return this.vcr.credit.days;
        },
        soldDays() {
            if (!this.creditDays) return null;
            let sold = this.vcr.credit.days - this.vcr.credit.vcr_days;
            if (this.vcr.days) {
                sold -= parseFloat(this.vcr.days);
            }
            if (this.vcr.id) {
                sold += parseFloat(this.originalVcrDays);
            }
            return Math.round(sold * 100) / 100;
        },
    },
    methods: {
        selectProject() {
            this.vcr.version = null;
            this.refreshVersions();
        },
        refreshVersions() {
            let vcr = this.vcr;
            if (!vcr.project) {
                this.versions = [];
                return;
            };
            this.$store.dispatch("Issue/list/getVersions", vcr.project.id).then((versions) => {
                this.versions = versions;
            });
        },
        selectCustomer() {
            this.vcr.opportunity = null;
            this.vcr.credit = null;
            this.refreshOpportunities();
        },
        refreshOpportunities() {
            let vcr = this.vcr;
            if (!vcr.customer) {
                this.opportunities = [];
                this.credits = [];
                return;
            }
            this.$store.dispatch("Credit/list/getOpportunities", vcr.customer.id).then((opportunities) => {
                this.opportunities = opportunities;
            });
            this.refreshCredits();
        },
        selectOpportunity() {
            this.vcr.credit = null;
            this.refreshCredits();
        },
        refreshCredits() {
            const vm = this;
            let vcr = vm.vcr;
            if (!vcr.customer && !vcr.opportunity) {
                vm.credits = [];
                return;
            };
            this.$store.dispatch("Credit/list/getCreditLines", {
                customerId: vcr.customer.id,
                opportunityId: vcr.opportunity?.id
            }).then((creditLines) => {
                vm.credits = creditLines;
            });
        },
        loadLists() {
            this.$store.dispatch("Project/list/getList");
            this.$store.dispatch("Credit/list/getCustomers");
            this.refreshVersions();
            this.refreshOpportunities();
            this.refreshCredits();
        },
        validate() {
            const requiredFields = ['version', 'credit', 'days'];
            requiredFields.forEach(requiredField => {
                this.errors[requiredField] = !this.vcr[requiredField];
            });
            return !Object.values(this.errors).find(error => error);
        },
        submit(then) {
            if (!this.validate()) return;
            const vcredit = versionCredit4API(this.vcr);
            this.$store.dispatch("Credit/list/edit", vcredit).then(() => {
                this.errorMessages = defaultErrorMessages;
                then();
            }).catch(error => {
                if (error.response?.data?.errors) {
                    this.errorMessages = error.response?.data?.errors;
                }
            });
        },
        submitClose() {
            const vm = this;
            this.submit(() => {
                vm.$emit("saved");
                vm.close();
            })
        },
        submitContinue() {
            const vm = this;
            this.submit(() => {
                vm.refreshOpportunities();
                vm.vcr.id = null;
                vm.vcr.credit = null;
                vm.vcr.days = null;
                vm.$emit("saved");
            })
        },
        close() {
            //   Object.assign(this.$data, this.$options.data.apply(this));
            this.$emit("close");
        },
    },
    mounted() {
        if (this.vcr.id) {
            this.$refs.days.focus();
        } else {
            this.$refs.project.$el.focus();
        }
        this.loadLists();
    }
};
