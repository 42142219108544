function versionCredit4Modal(vcredit) {
    return {
        id: vcredit.id,
        project: {
            id: vcredit.project_id,
            name: vcredit.project_name
        },
        version: {
            id: vcredit.version_id,
            name: vcredit.version_name
        },
        customer: {
            id: vcredit.customer_id,
            name: vcredit.customer_name
        },
        opportunity: {
            id: vcredit.opportunity_id,
            name: vcredit.opportunity_name
        },
        credit: {
            id: vcredit.credit_id,
            name: vcredit.customer_profile_name,
            days: vcredit.credit_days,
            vcr_days: vcredit.total,
        },
        days: vcredit.days,
        description: vcredit.description,
    };
}

function versionCredit4API(vcr) {
    return {
        id: vcr?.id,
        versionId: vcr.version.id,
        credit: vcr.credit.id,
        days: vcr.days,
        description: vcr.description,
    };
}

export { versionCredit4Modal, versionCredit4API }
