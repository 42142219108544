<template>
  <tr @click="$emit('open')" :class="{ 'text-danger': !vcredit.version_id }">
    <td>{{ vcredit.project_name }}</td>
    <td>{{ vcredit.version_name }}</td>
    <td>{{ vcredit.customer_name }}</td>
    <td>{{ vcredit.opportunity_name }}</td>
    <td>{{ vcredit.customer_profile_name }}</td>
    <td class="text-right">{{ vcredit.days }}</td>
    <td class="text-right">{{ vcredit.credit_days }}</td>
    <td class="text-right">{{ Math.round((vcredit.credit_days - vcredit.total) * 100) / 100 }}</td>
    <td ref="price" :class="['text-right', {discounted}]" :data-discounts="discounts">{{ price }}</td>
    <td :title="vcredit.description">
      <icon-comment v-if="vcredit.description" />
    </td>
    <td class="action" @click.stop="$emit('remove')">
      <icon-delete class="icon_delete" />
    </td>
  </tr>
</template>

<script>
import tippy from "tippy.js";
import { moneyFormat } from "@/app/helpers";
import IconDelete from "@/icons/minus-square-solid.svg";
import IconComment from "@/icons/comment-alt-solid.svg";

export default {
  name: "VersionsCreditsLine",
  props: ["vcredit"],
  components: { IconDelete, IconComment },
  computed: {
    price() {
      let price = this.vcredit.price;
      if (this.vcredit.price_discounted) {
        price = this.vcredit.price_discounted;
      }
      // if (this.vcredit.discount_opportunity_percentage) {
      //   price *= 1 - this.vcredit.discount_opportunity_percentage / 100;
      // }
      return moneyFormat(price);
    },
    discounted() {
      if (this.vcredit.price_discounted) return true;
      if (this.vcredit.discount_opportunity_percentage) return true;
      if (this.vcredit.discount_opportunity_amount) return true;
      return false;
    },
    discounts() {
      if (!this.discounted) return null;
      let discounts = [];
      if (this.vcredit.price_discounted) {
        discounts.push(
          `Prix initial: <b>${moneyFormat(this.vcredit.price)}</b>`
        );
        discounts.push(
          `Prix remisé: <b>${moneyFormat(this.vcredit.price_discounted)}</b>`
        );
      }
      if (this.vcredit.discount_opportunity_percentage) {
        discounts.push(
          `Bas de page: <b>${this.vcredit.discount_opportunity_percentage} %</b>`
        );
      }
      if (this.vcredit.discount_opportunity_amount) {
        discounts.push(
          `Bas de page: <b>${moneyFormat(
            this.vcredit.discount_opportunity_amount
          )}</b>`
        );
      }
      return "<h4>Remise(s)</h4>" + discounts.join("<br>");
    },
  },
  methods: {
    refreshTips() {
      tippy(this.$refs.price, {
        onShow: (instance) => {
          return "discounts" in instance.reference.dataset;
        },
        content: (ref) => {
          return ref.dataset.discounts;
        },
        allowHTML: true,
      });
    },
  },
  mounted() {
    this.refreshTips();
  },
};
</script>
