import { mapGetters } from "vuex";
import IconAdd from "@/icons/plus-square-solid.svg";
import IconCredits from "@/icons/coins-solid.svg";
import { versionCredit4Modal } from "./versions-credits-helpers";
import { userIsAtLeastDP } from '@/app/helpers';
import VersionsCreditsModal from "./VersionsCreditsModal.vue";
import VersionsCreditsLine from "./VersionsCreditsLine.vue";

const PAGE_SIZE = 50;

// function bottomReached() {
//     return Math.max(
//         window.pageYOffset,
//         document.documentElement.scrollTop,
//         document.body.scrollTop
//     ) +
//         window.innerHeight ===
//         document.documentElement.offsetHeight;
// }

export default {
    name: "VersionsCredits",
    data: () => {
        return {
            modalOpen: false,
            vcreditOpened: null,
            filter: null,
            onlyDeletedVersions: false,
            versionCredits: [],
            total: 0,
            page: 1,
            finished: false,
            retrieveCustomersBtnDisabled: false,
            retrieveCreditsBtnDisabled: false,
        };
    },
    components: {
        IconAdd,
        IconCredits,
        VersionsCreditsModal,
        VersionsCreditsLine,
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            loading: "App/loading/loading",
            user: "Resource/auth/user",
        }),
        canRetrieveCredits() {
            return userIsAtLeastDP(this.user);
        },
    },
    methods: {
        open(vcredit) {
            if (!vcredit.type) {
                // Edit
                this.vcreditOpened = versionCredit4Modal(vcredit);
            }
            this.modalOpen = true;
        },
        close() {
            this.vcreditOpened = null;
            this.modalOpen = false;
        },
        loadPage(page = this.page, limit = PAGE_SIZE) {
            const vm = this;
            const payload = {
                filter: this.filter,
                onlyDeletedVersions: this.onlyDeletedVersions ? '' : null,
                page,
                limit
            };
            return this.$store.dispatch("Credit/list/getList", payload).then(data => {
                if ((this.versionCredits.length + data.data.length) == data.total) vm.finished = true;
                return data;
            });
        },
        refresh() {
            const vm = this;
            vm.versionCredits = [];
            this.loadPage(1, this.page * PAGE_SIZE).then((data) => {
                vm.versionCredits = data.data;
                vm.total = data.total;
            });
        },
        search() {
            this.finished = false;
            this.page = 1;
            this.refresh();
        },
        remove(vcredit) {
            if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette version/crédit ?"))
                return;
            const vm = this;
            this.$store.dispatch("Credit/list/delete", vcredit.id).then(() => {
                vm.refresh();
            });
        },
        loadNextPage() {
            const vm = this;
            if (this.loading) return;
            if (this.finished) return;
            vm.page++;
            this.loadPage().then((data) => {
                vm.versionCredits.push(...data.data);
            });
        },
        // handleScroll() {
        //     if (bottomReached()) {
        //         this.loadNextPage();
        //     }
        // },
        retrieveCustomers() {
            if (!window.confirm('Êtes-vous sûr de vouloir récupérer les clients ?')) return;
            this.retrieveCustomersBtnDisabled = true;
            this.$store.dispatch("Credit/list/retrieveCustomers").then(data => {
                this.retrieveCustomersBtnDisabled = false;
                console.info(data);
            });
        },
        retrieveCreditLines() {
            if (!window.confirm('Êtes-vous sûr de vouloir récupérer les lignes de crédit ?')) return;
            this.retrieveCreditsBtnDisabled = true;
            this.$store.dispatch("Credit/list/retrieveCreditLines").then(data => {
                this.retrieveCreditsBtnDisabled = false;
                console.info(data);
            });
        },
    },
    mounted() {
        this.refresh();
        // window.removeEventListener("scroll", this.handleScroll);
        // window.addEventListener("scroll", this.handleScroll);
    }
};
